<template>
    <div class="doctorInfo_container global_full_screen">
        <itemScroll ref="itemScroll">
            <div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point">*</span>专家姓名</div>
                    <div class="doctorInfo_value global_text_input">
                        <el-input v-model="data.name" placeholder="请输入专家姓名"></el-input>
                    </div>
                    <div class="global_container_mask"></div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point">*</span>手机号</div>
                    <div class="doctorInfo_value global_text_input">
                        <el-input v-model="data.phone" placeholder="请输入手机号"></el-input>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point">*</span>身份证号</div>
                    <div class="doctorInfo_value global_text_input">
                        <el-input v-model="data.id_card_number" placeholder="请输入身份证号"></el-input>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point">*</span>开户银行<span class="doctorInfo_text_gray">（示例：交通银行xx支行/营业部）</span></div>
                    <div class="doctorInfo_value global_text_input">
                        <el-input v-model="data.bank" placeholder="请输入开户银行"></el-input>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point">*</span>银行卡号</div>
                    <div class="doctorInfo_value global_text_input">
                        <el-input v-model="data.bank_number" placeholder="请输入银行卡号"></el-input>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point">*</span>身份证照片</div>
                    <div class="doctorInfo_value">
                        <div class="doctorInfo_card_front doctorInfo_card" @click="selectFile('id_card_front')">
                            <div class="doctorInfo_card_none">
                                <div v-if="!data.id_card_front" class="doctorInfo_card_none_img_block" >
                                    <img  class="doctorInfo_card_none_img"  :src="require('@/assets/images/icon/add_photo.png')" alt="">
                                    <span>身份证正面</span>
                                </div>
                                <img class="doctorInfo_card_img"  @load="initScroll" v-else-if="!data.id_card_front.includes('*')" :src="data.id_card_front" alt="">
                                <div class="doctorInfo_card_already_update global_container_center" v-else>已上传</div>
                            </div>
                        </div>
                        <div class="doctorInfo_card_backend doctorInfo_card" @click="selectFile('id_card_back')">
                            <div class="doctorInfo_card_none">
                                <div v-if="!data.id_card_back" class="doctorInfo_card_none_img_block" >
                                    <img  class="doctorInfo_card_none_img"  :src="require('@/assets/images/icon/add_photo.png')" alt="">
                                    <span>身份证反面</span>
                                </div>
                                <img class="doctorInfo_card_img"  @load="initScroll" v-else-if="!data.id_card_back.includes('*')" :src="data.id_card_back" alt="">
                                <div class="doctorInfo_card_already_update global_container_center" v-else>已上传</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point" v-if="projectInfo.doctor_page">*</span>专家简历职称<span class="doctorInfo_text_gray">（示例：主任医师、博导、硕导、教授等）</span></div>
                    <div class="doctorInfo_value global_text_textarea">
                        <el-input v-model="data.resume" type="textarea" placeholder="请输入专家简历职称"></el-input>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key">简历行政职务<span class="doctorInfo_text_gray">（示例：副主任等）</span></div>
                    <div class="doctorInfo_value global_text_input">
                        <el-input v-model="data.job" placeholder="请输入专家简历行政职务"></el-input>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point" v-if="projectInfo.doctor_page">*</span>简历学术任职及其他</div>
                    <div class="doctorInfo_value global_text_textarea">
                        <el-input ref="doctorjobInput" 
                            v-model="data.resume_academic" 
                            type="textarea" 
                            placeholder=""
                            :rows="6"
                            @blur='onblur'
                            @focus='onfocus'>
                        </el-input>
                        <span @click='setFocus' v-if='!data.resume_academic && config.showPlaceholder' class='doctorjobInput_placeholder' v-html="config.placeholder"></span>
                    </div>
                </div>
                <div class="doctorInfo_row ">
                    <div class="doctorInfo_key"><span class="global_red_point" v-if="projectInfo.doctor_page">*</span>简历照片</div>
                    <div class="doctor_profile" @click="selectFile('profile')" :class="{doctor_background:!data.profile}">
                        <img :src="require('@/assets/images/icon/resume_img.png')" v-if="!data.profile" alt=""/>
                        <!-- <div v-if="!data.profile">

                        </div> -->
                        <img :src="data.profile" @load="initScroll" v-else alt=""/>
                    </div>
                </div>
                <div class="doctorInfo_row doctorInfo_button_group">
                    <customButton class="doctorInfo_button" size="small" @click="doCommit">
                        <div class="doctorInfo_button_info">
                            <img :src="require('@/assets/images/icon/apply.png')" alt="" />
                            <span>提交信息</span>
                        </div>
                    </customButton>
                </div>
            </div>
        </itemScroll>
        <input type="file" @change="changeFile" ref="selectFile" v-if="manage.resetFile" v-show="false">
        <imageCropper @receiveData="receiveData" ref="imageCropper" :CropWidth="manage.key == 'profile'?150:300" :flexScale="manage.key == 'profile'?[1,1]:[2,1]" :isShowHeadimgCalibration="manage.key == 'profile'"  v-if="manage.url"></imageCropper>
    </div>
</template>
<script>
import { ElLoading,ElInput } from 'element-plus'
import customButton from '@/components/unit/customButton'
import itemScroll from '@/components/unit/itemScroll'
import imageCropper from '@/components/unit/imageCropper'
import weixin from '@/common/weixin'
export default {
    name:"doctorInfo",
    components:{
        itemScroll:itemScroll,
        customButton:customButton,
        imageCropper:imageCropper,
        ElInput
    },
    data(){
        return {
            projectInfo:{},
            data:{
                name:"",
                phone:"",
                id_card_number:"",
                bank:"",
                bank_number:"",
                id_card_front:"",
                id_card_back:"",
                resume:"",
                job:"",
                resume_academic:"",
                profile:""
            },
            splitReg:/[,\n]+/g,
            manage:{
                key:"",
                resetFile:true,
                url:""
            },
            config:{
                showPlaceholder:true,
                placeholder:"示例：<br>河南省医学会呼吸病学分会间质性肺疾病学组委员会<br>河南省医学会变态反应分会感染学组委员<br>河南省呼吸与危重症学会间质性肺疾病学分会副主任委员"
            },
            hasPage:false,
            clickTime:0
        }
    },
    created(){
        document.getElementsByTagName("title")[0].innerHTML = "个人信息"
        this.loadData()
        this.hasDoctorPage()
        this.loadProjectInfo()
    },
    methods:{
        setFocus(){
            this.config.showPlaceholder = false;
            this.$refs.doctorjobInput.focus()
        },
        onblur(){
            this.$refs.doctorjobInput.blur()
            this.config.showPlaceholder = true;
        },
        onfocus(){
            this.config.showPlaceholder = false;
        },
        
        loadProjectInfo(){
            let url = this.$tools.getURL(
                this.$urls.admin.project,
                {
                    project_id:this.$tools.getStorage("projectId",sessionStorage)
                }
            )
            this.$axios.get(url).then(res=>{
                this.projectInfo = res.data
            }).catch(err=>{})
        },
        loadData(){
            let url = this.$tools.getURL(this.$urls.admin.doctorInfo,this.$route.query)
            this.$axios.get(url).then(res=>{
                this.data = res.data;
                for(let k in this.data){
                    if(this.data[k]== null){
                        this.data[k] = ""
                    }
                }
                this.data.resume_academic = this.$tools.trimEnter(this.data.resume_academic).replace(this.splitReg,"\n")
                this.data.project_id = this.$tools.getStorage("projectId",sessionStorage)
                setTimeout(()=>{
                    this.initScroll()
                },2000)
            })
        },
        initScroll(){
            this.$refs.itemScroll.initScroll()
        },
        hasDoctorPage(){
            let adminInfo = this.$store.state.common.adminInfo;
            let doctorID = this.$route.query.id;
            let url = this.$tools.getURL(this.$urls.doctor.hasPage,{
                id:doctorID,
                phone:adminInfo.phone,
                project_id:this.$tools.getStorage("projectId",sessionStorage)
            })
            this.$axios.get(url).then(res=>{
                this.hasPage = Boolean(res.data)
            }).catch(err=>{})
        },
        selectFile(key){
            if(this.loading) return;
            this.manage.key = key;
            if(this.$tools.isLocal()){
                this.$refs.selectFile.click();
            }else{
                this.loading = true;
                // this.receiveData({url:"http://kclass.imedunion.com/image/2/132/card/8e81d9522a8364539525c947a538e6ed_new.png"})
                weixin.selectFile(1,999999).then(files=>{
                    setTimeout(()=>{ this.loading = false },1000)
                    // this.data.doctorHeadImg = files[0]
                    this.manage.url = files[0]
                    this.$nextTick(()=>{
                        this.$refs.imageCropper.setData(this.manage.url)
                    })
                }).catch(err=>{
                    setTimeout(()=>{ this.loading = false },1000)
                })
            }
        },
        changeFile(event){
            let file = event.target.files[0];
            if(!file) return;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (item) => {
                this.manage.url = item.target.result;
                this.manage.resetFile = false;
                this.$nextTick(()=>{
                    this.manage.resetFile = true;
                    this.$refs.imageCropper.setData(this.manage.url)
                })
            }
        },
        receiveData(data){
            this.manage.url = ""
            if(this.manage.key == "profile"){
                this.dealwithProfile(data.url)
            }else{
                this.data[this.manage.key] = data.url;
            }

        },
        
        dealwithProfile(url){
            let loading = this.$tips.customLoading()
            let data = {
                profile:url
            }
            this.$axios.post(this.$urls.doctor.image,data).then(res=>{
                this.data[this.manage.key] = res.profile;
                this.manage.url = ""
                loading.close && loading.close()
                loading = null
            }).catch((err )=>{
                loading.close && loading.close()
                loading = null
            })
            
        },
        isHide(key){
            return String(this.data[key] || "").includes("*")
        },
        verifyData(){
            if (!this.data.phone && !this.isHide("phone")) {
                this.$tips.error({ text: "请输入手机号" });
                return false;
            }
            if (!/^1\d{10}$/g.test(this.data.phone) && !this.isHide("phone")) {
                this.$tips.error({ text: "请输入格式正确的手机号" });
                return false;
            }
            if (!this.data.id_card_number && !this.isHide("id_card_number")) {
                this.$tips.error({ text: "请输入身份证号" });
                return false;
            }
            let reg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
            if (!reg.test(this.data.id_card_number) && !this.isHide("id_card_number")) {
                this.$tips.error({ text: "身份证号不合法" });
                return false;
            }
            if (!this.data.bank) {
                this.$tips.error({ text: "请输入开户银行" });
                return false;
            }
            if (!this.data.bank_number && !this.isHide("bank_number")) {
                this.$tips.error({ text: "请输入银行账号" });
                return false;
            }
            if (!/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(this.data.bank_number) && !this.isHide("bank_number")) {
                this.$tips.error({ text: "错误的银行卡号" });
                return false;
            }
            if (!this.data.id_card_front ) {
                this.$tips.error({ text: "请上传身份证正面" });
                return false;
            }
            if (!this.data.id_card_back) {
                this.$tips.error({ text: "请上传身份证反面" });
                return false;
            }
            if(this.hasPage){
                if (!this.data.resume) {
                    this.$tips.error({ text: "请输入专家简历职称" });
                    return false;
                }
                // if (!this.data.job) {
                //     this.$tips.error({ text: "请输入专家简历行政职务" });
                //     return false;
                // }
                if (!this.data.resume_academic) {
                    this.$tips.error({ text: "请输入专家简历学术任职及其他" });
                    return false;
                }
                if (!this.data.profile) {
                    this.$tips.error({ text: "请上传专家简历头像" });
                    return false;
                }
                
                let resumeAcademic = this.$tools.trimEnter(this.data.resume_academic).split(this.splitReg)
                let resumeAcademicCount = 0;
                resumeAcademic.forEach(val=>{
                    let textCount = Math.ceil(val.length/this.$config.resume.text)
                    resumeAcademicCount += textCount
                })
                if(resumeAcademicCount > this.$config.resume.row){
                    this.$tips.error({text:'专家简历最多只能输入七行'})
                    return false
                }

            }
            
            return true;
        },
        doCommit(){
            if(this.clickTime + 1500 > Date.now()) return;
            this.clickTime = Date.now()
            if(this.loading || !this.verifyData()) return;
            this.loading = true;
            const loadingPage = ElLoading.service({
                lock: true,
                text: '资料上传中,请稍后',
                background: 'rgba(255, 255, 255, 0.5)',
            })
            this.$axios.patch(this.$urls.admin.doctorInfo,this.data).then(res=>{
                // setTimeout(()=>{ this.loading = false },1000)
                this.$tips.success({ text: "完善成功" });
                loadingPage.close()
                // loadingPage = null
                this.$router.back()
            }).catch(err=>{
                loadingPage.close()
                setTimeout(()=>{ this.loading = false },1000)
            })
        },
    }
}
</script>
<style >
    .doctorInfo_container{
        position:relative;
        padding:4vw;
        background:#fff;
    }
    .doctorInfo_row{
        display: flex;
        flex-direction:column;
        margin-bottom:3.2vw;
        position: relative;
    }
    .doctorInfo_key{
        margin-bottom:2.4vw;
    }
    .doctorInfo_text_gray{
        color:#686868;
        font-size:3.6vw;
    }
    .doctorInfo_card{
        width:100%;
        display: flex;
        justify-content: center;
        margin:2.8vw 0;
    }
    .doctorInfo_card_none{
        width:74.66vw;
        height:37.33vw;
        border:.13vw solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .doctorInfo_card_none_img_block{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .doctorInfo_card_none_img_block span{
        color:#ccc;
        margin-top:1vw;
    }
    .doctorInfo_card_none_img{
        width:18.13vw;
        height:auto;
    }
    .doctorInfo_card_img{
        width:100%;
        height:100%;
    }
    .doctorInfo_button_group{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .doctorInfo_button{
        width:74.66vw;
        height:9.4vw;
    }
    .doctorInfo_button_info{
        height:100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    .doctorInfo_button_info img{
        height: 5.1vw;
        width:auto;
        margin-right:1vw;
    }
    .doctorInfo_value{
        border:1px solid #e6e6e6;
    }
    .doctorjobInput_placeholder{
        font-size: 3.5vw;
        display: inline-block;
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        padding: 2vw;
        color:#c4c4cc;
    }
    .doctor_profile{
        width: calc(30.4vw + 2px);
        height:  calc(30.4vw + 2px);
        border:1px solid #000;
    }
    .doctor_background{
        background: #686868;
    }
    .doctor_profile img{
        width: 30.4vw;
        height: 30.4vw;
        border-radius:30.4vw;
    }
    .doctorInfo_card_already_update{
        color:#686868;
    }
</style>